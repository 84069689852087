import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Connection } from '../../types/Connection';

interface Props {
  accountId: string;
  connection: Connection;
}

const AccountConnectionList = ({ accountId, connection }: Props): ReactElement => {
  return (
      <tr key={connection.id}>
        <td>
          <Link style={{color: 'inherit'}} to={{
            pathname: `/connection/${connection.id}`,
          }}>
            {connection.name}
            </Link>
        </td>
        <td>
        <Link style={{color: 'inherit'}} to={{
            pathname: `/connection/${connection.id}`,
          }}>
            {connection.id}
          </Link>
        </td>
      </tr>
    
  );
};

export default AccountConnectionList;
