import { ApiActionType, ApiAction } from './actions';
import Themes from '../../types/Themes';

export interface ApiState {
  error: Error | null;
  godMode: boolean;
  theme: Themes;
  token: string;
}

const darkThemeMedia = window.matchMedia('(prefers-color-scheme: dark)');

export const apiInitialState: ApiState = {
  error: null,
  godMode: false,
  theme: darkThemeMedia.matches ? Themes.DARK : Themes.LIGHT,
  token: '',
};

function apiReducer(state: ApiState = apiInitialState, action: ApiAction): ApiState {
  switch (action.type) {
    case ApiActionType.SetError:
      return {
        ...state,
        error: action.payload,
      };
    case ApiActionType.SetGodMode:
      return {
        ...state,
        godMode: action.payload,
      };
    case ApiActionType.SetToken:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
}

export default apiReducer;
