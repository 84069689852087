import qs from 'query-string';
import * as R from 'ramda';
import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import { useSetShow } from '../../../services/modal/actions';
import { useGetDetail, UseGetDetail } from '../../../services/modal/api';
import { useFetchList, UseFetchList } from '../../../services/list/api';
import { ListState } from '../../../services/list/index';
import { useList, useApi, useInit } from '../../../services/selectors';
import { FilterState } from '../../../types/Request';
import { useEmptyList } from '../../../services/list/actions';

interface UseRequestHook {
  fetchDetail: UseGetDetail['fetchDetail'];
  fetchList: UseFetchList['fetchList'];
  isLoadingDetail: UseGetDetail['isLoading'];
  isLoadingList: UseFetchList['isLoading'];
  lastRowRef: RefObject<HTMLElement> | ((node?: Element | null) => void);
  list: ListState['data'];
  filterInput: FilterState;
  setFilterInput: React.Dispatch<React.SetStateAction<FilterState>>;
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
}

export const UseRequestHook = (showAll: boolean, accountId?: string): UseRequestHook => {
  const { token } = useApi();
  const { search } = useLocation();
  const { fetchDetail, isLoading: isLoadingDetail } = useGetDetail();
  const { fetchList, isLoading: isLoadingList } = useFetchList();
  const { data } = useList();
  const emptyList = useEmptyList();
  
  const setShow = useSetShow();

  const [filterInput, setFilterInput] = useState({
    account_id: accountId ?? '',
    product_id: '',
    connection_id: '',
    connection_channel: '',
    action: '',
    env: '',
    connection_backend: '',
    status: '',
    subrequests: '',
    date_from: '',
    date_to: '',
    service: ''
  });

  const [searchInput, setSearchInput] = useState('');

  const init = useInit();
  const handleInit = useCallback(init, [init, token]);

  const { inView, ref } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      if (searchInput) {
        const created = data[data.length - 1].created_at.slice(0, -6);
        const args = {
          append: true,
          created,
          search: searchInput,
        };
        fetchList(args);
      } else {
        const created = data[data.length - 1].created_at.slice(0, -6);
        const args = {
          append: true,
          created,
          ...filterInput,
        };
        if (showAll) {
          fetchList(args);
        } else {
          fetchList({
            ...args,
            account_id: accountId ?? '',
          })
        }
      };
    };
  }, [inView, fetchList]);

  useEffect(() => {
    const parsed = qs.parse(search);
    const account_id = parsed?.account_id?.toString() ?? '';
    const product_id = parsed?.product_id?.toString() ?? '';
    const connection_id = parsed?.connection_id?.toString() ?? '';
    const connection_channel = parsed?.connection_channel?.toString() ?? '';
    const action = parsed?.action?.toString() ?? '';
    const env = parsed?.env?.toString() ?? '';
    const connection_backend = parsed?.connection_backend?.toString() ?? '';
    const status = parsed?.status?.toString() ?? '';
    const subrequests = parsed?.subrequests?.toString() ?? '';
    const date_from = parsed?.date_from?.toString() ?? '';
    const date_to = parsed?.date_to?.toString() ?? '';
    const service = parsed?.service?.toString() ?? '';

    const searchParam = parsed?.search?.toString() ?? '';

    if (searchParam) {
      emptyList();
      setSearchInput(searchParam);
      if (!R.isEmpty(token)) {
        fetchList({
          search: searchParam
        });
      }
    } else {
      const newFilter = {
        account_id: showAll ? account_id : accountId ?? '',
        product_id,
        connection_id,
        connection_channel,
        action,
        env,
        connection_backend,
        status,
        subrequests,
        date_from,
        date_to,
        service
      };
      
      emptyList();
      setFilterInput(newFilter)
  
      if (!R.isEmpty(token)) {
        fetchList(newFilter);
      }
    }

  }, [fetchList, token]);

  useEffect(() => {
    handleInit();
  }, [handleInit]);

  useEffect(() => {
    const parsed = qs.parse(search);
    const detail = parsed?.detail?.toString() ?? '';

    if (!R.isEmpty(detail)) {
      fetchDetail(detail);
    }
  }, [setShow]);

  return {
    fetchDetail,
    fetchList,
    isLoadingDetail,
    isLoadingList,
    lastRowRef: ref,
    list: data,
    filterInput,
    setFilterInput,
    searchInput,
    setSearchInput,
  };
};
