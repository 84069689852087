import * as R from 'ramda';
import { useSetDetail, useSetShow } from './actions';
import { RequestHandlerParams, useRequestHandler } from '../api/api';
import { RequestDetail } from '../../types/Request';
import endpoints, { GOD_TOKEN } from '../../consts/api';

export interface UseGetDetail {
  fetchDetail: (id: string) => void;
  isLoading: boolean;
}

export const useGetDetail = (): UseGetDetail => {
  const setDetail = useSetDetail();
  const { handleRequest, isLoading } = useRequestHandler<RequestDetail>();
  const setShow = useSetShow();

  const fetchDetail = async (id: string) => {
    setShow(true);
    const params: RequestHandlerParams = {
      method: 'GET',
      token: GOD_TOKEN,
      url: `${endpoints.REQUESTS}/${id}`,
    };
    const detail = await handleRequest(params);

    if (!R.isNil(detail)) {
      setDetail(detail);
    } else {
      setShow(false);
    }
  };

  return { fetchDetail, isLoading };
};
