import { Connection } from '../../types/Connection';
import { useDispatch } from '../state/dispatch';

export enum AccountConnectionListActionType {
  SetAccountConnectionList = 'SET_ACCOUNT_CONNECTION_LIST',
}

interface SetAccountConnectionList {
  type: AccountConnectionListActionType.SetAccountConnectionList;
  payload: Connection[];
}

type UseSetList = (list: Connection[]) => void;

export const useSetAccountConnectionList = (): UseSetList => {
  const dispatch = useDispatch();

  return (list: Connection[]) => {
    return dispatch({
      type: AccountConnectionListActionType.SetAccountConnectionList,
      payload: list,
    });
  };
};

export type AccountConnectionListAction = SetAccountConnectionList;
