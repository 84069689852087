import React, { ReactElement } from 'react';

interface Props {
  size?: number;
}

function SvgPlus({ size = 24 }: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.1"
      viewBox="0 -14 1792 1792"
      style={{
        width: size,
        height: size,
      }}
    >
      <g transform="matrix(1 0 0 -1 205.017 1368.95)">
        <path
          fill="currentColor"
          d="M1408 800V608q0-40-28-68t-68-28H896V96q0-40-28-68T800 0H608q-40 0-68 28t-28 68v416H96q-40 0-68 28T0 608v192q0 40 28 68t68 28h416v416q0 40 28 68t68 28h192q40 0 68-28t28-68V896h416q40 0 68-28t28-68z"
        ></path>
      </g>
    </svg>
  );
}

export default SvgPlus;
