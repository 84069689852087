import { BookingListAction, BookingsListActionType } from './actions';

export interface BookingsListState {
  data: any;
}
export const bookingsListInitialState: BookingsListState = {
  data: null,
};

function bookingsReducer(
  state: BookingsListState = bookingsListInitialState,
  action: BookingListAction,
): BookingsListState {
  switch (action.type) {
    case BookingsListActionType.SetBookings:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
}

export default bookingsReducer;
