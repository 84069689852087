import * as R from 'ramda';
import React, { ReactElement } from 'react';

import { Json } from '../../../types/Http';

import HighlightArray from './HighlightArray';
import HighlightBoolean from './HighlightBoolean';
import HighlightNil from './HighlightNil';
import HighlightNumber from './HighlightNumber';
import HighlightObject from './HighlightObject';
import HighlightString from './HighlightString';

interface Props {
  attr?: boolean;
  last?: boolean;
  level?: number;
  onShowClick?: () => void;
  show?: boolean;
  value: Json;
}

const HighlightJson = ({
  attr = false,
  last = true,
  level = 0,
  onShowClick,
  show = true,
  value,
}: Props): ReactElement => {
  const props = {
    attr: attr,
    last: last,
    level: level,
    value: value,
  };

  switch (R.type(value)) {
    case 'Array':
      return <HighlightArray {...props} onShowClick={onShowClick} show={show} />;
    case 'Boolean':
      return <HighlightBoolean {...props} />;
    case 'Null':
      return <HighlightNil {...props} />;
    case 'Number':
      return <HighlightNumber {...props} />;
    case 'Object':
      return <HighlightObject {...props} onShowClick={onShowClick} show={show} />;
    case 'String':
      return <HighlightString {...props} />;
  }

  return <span />;
};

export default HighlightJson;
