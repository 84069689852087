import * as R from 'ramda';
import React, { ReactElement } from 'react';

import { HttpRequest } from '../../../types/Http';

interface Props {
  request: HttpRequest;
}

const HighlightRequestFirstLine = ({ request }: Props): ReactElement => {
  const path = !R.isEmpty(request.url) ? new URL(request.url).pathname : '';
  const version = request.headers['version'] ?? '1.1';

  return (
    <>
      <span className="hljs-keyword">{request.method}</span>{' '}
      <span className="hljs-link">{path}</span> <span className="hljs-keyword">HTTP/{version}</span>
      {'\n'}
    </>
  );
};

export default HighlightRequestFirstLine;
