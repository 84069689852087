import React, { ReactElement } from 'react';

interface Props {
  size?: number;
}

const SvgSearch = ({ size = 24 }: Props): ReactElement => {
  return (
    <svg
      className="icon"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      style={{
        width: size,
        height: size,
      }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="10" cy="10" r="7" />
      <line x1="21" y1="21" x2="15" y2="15" />
    </svg>
  );
};

export default SvgSearch;
