import { useCallback } from 'react';
import { RequestHandlerParams, useRequestHandler } from '../api/api';
import endpoints, { GOD_TOKEN } from '../../consts/api';
import { useSetMetaData } from './actions';

export interface UseMetaDataFetch {
  fetchMetaData: () => void;
  isLoading: boolean;
}

export const useMetaDataFetch = (): UseMetaDataFetch => {
  const { isLoading, handleRequest } = useRequestHandler();
  const setMetaData = useSetMetaData();

  const fetchMetaData = useCallback(
    async () => {
      try {
        const params: RequestHandlerParams = {
          method: 'GET',
          url: `${endpoints.METADATA}`,
          token: GOD_TOKEN
        };
        const metaData = await handleRequest(params);

        if (!metaData) {
          return setMetaData(null);
        }

        return setMetaData(metaData);
      } catch (err) {
        console.log(err);
        return setMetaData(null);
      }
    },
    [handleRequest, setMetaData],
  );

  return { fetchMetaData, isLoading };
};
