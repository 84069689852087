import { useCallback } from 'react';
import { RequestHandlerParams, useRequestHandler } from '../api/api';
import endpoints from '../../consts/api';
import { BookingsSearchParams } from '../../types/Bookings';
import { useSetBookingList } from './actions';

export interface UseBookingFetchList {
  fetchBookings: (fetchData: BookingsSearchParams) => void;
  resetBookingsList: () => void;
  isLoading: boolean;
}

export const useBookingFetchList = (): UseBookingFetchList => {
  const { isLoading, handleRequest } = useRequestHandler();
  const setBookingList = useSetBookingList();

  const fetchBookings = useCallback(
    async (fetchData: BookingsSearchParams) => {
      try {
        const params: RequestHandlerParams = {
          method: 'GET',
          url: `${endpoints.ACCOUNT_BOOKINGS}`,
          params: {
            type: fetchData.type,
            date_start: fetchData.date_start,
            date_end: fetchData.date_end,
          },
          accountID: fetchData.accountID,
        };
        const bookings = await handleRequest(params);

        if (!bookings) {
          return setBookingList(null);
        }

        return setBookingList(bookings);
      } catch (err) {
        console.log(err);
        return setBookingList(null);
      }
    },
    [handleRequest, setBookingList],
  );

  const resetBookingsList = useCallback(async () => {
    try {
      return setBookingList(null);
    } catch (error) {
      return setBookingList(null);
    }
  }, [setBookingList]);

  return { fetchBookings, resetBookingsList, isLoading };
};
