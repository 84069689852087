import { useReducer, useEffect } from 'react';
import endpoints, { GOD_TOKEN } from '../consts/api';
import { SweetAlertIcon } from 'sweetalert2';
import { useRequestHandler } from '../services/api/api';
import accountListReducer, { accountListInitialState } from '../services/accountList';
import { AccountListActionType } from '../services/accountList/actions';
import { Account } from '../types/Account';
import { IFeedback } from './utils';
import Themes from '../types/Themes';
import { useApi } from '../services/selectors';

const getIcon = (status: string): SweetAlertIcon => {
  switch (status) {
    case 'success':
      return 'success';
    case 'error':
      return 'error';
    case 'warning':
      return 'warning';
    case 'info':
      return 'info';
    default:
      return 'success';
  }
};

interface ICreateAccount {
  createAccount: (name: string) => Promise<IFeedback>;
}

export const useCreateAccount = (): ICreateAccount => {
  const reducerArr = useReducer(accountListReducer, accountListInitialState);
  const dispatch = reducerArr[1];
  const { handleRequest } = useRequestHandler<Account>();

  const createAccount = async (name: string) => {
    try {
      const data = await handleRequest({
        body: { name },
        method: 'POST',
        url: endpoints.CREATE_ACCOUNT,
        token: GOD_TOKEN,
      });
      if (!data) {
        throw new Error('No data');
      }

      dispatch({ type: AccountListActionType.AddAccount, payload: data });
      return {
        text: `Account ${data.name} created`,
        icon: getIcon('success'),
      };
    } catch (err) {
      return {
        text: `An error: ${(err as Error).message} occured`,
        icon: getIcon('error'),
      };
    }
  };

  return { createAccount };
};

export const useInit = (): void => {
  const { theme } = useApi();
  
  useEffect(() => {
    if (theme === Themes.DARK) {
      document.body.classList.add('theme-dark');
    } else {
      document.body.classList.remove('theme-dark');
    }
  }, [theme]);
};
