import React, { ReactElement } from 'react';

interface Props {
  attr?: boolean;
  last?: boolean;
  level?: number;
  value: null;
}

const HighlightNil = ({ attr = false, last = true, level = 0, value }: Props): ReactElement => {
  const spacer = ' '.repeat(level);

  return (
    <>
      {!attr && spacer}
      <span className="hljs-literal">{JSON.stringify(value)}</span>
      {!last && ','}
      {'\n'}
    </>
  );
};

export default HighlightNil;
