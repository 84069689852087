import { ReactElement } from 'react';
import { Col, Placeholder } from 'react-bootstrap';

interface Props {
  rows?: number;
}

const AccountListPlaceholder = ({ rows = 50 }: Props): ReactElement => {
  const getRandomWidth = () => {
    return Math.floor(Math.random() * (8 - 2) + 2)
  }
  const placeholder = Array.from(Array(rows).keys()).map(i => {
    return (
      <tr key={i}>
        <td>
          <Placeholder as={Col} animation="glow"><Placeholder xs={3} /></Placeholder>
        </td>
        <td>
          <Placeholder as={Col} animation="glow"><Placeholder xs={getRandomWidth()} /></Placeholder>
        </td>
        <td>
          <Placeholder as={Col} animation="glow"><Placeholder xs={getRandomWidth()} /></Placeholder>
        </td>
      </tr>
    )
  })
  return (
    <>
      {placeholder}
    </>
  );
};

export default AccountListPlaceholder;
