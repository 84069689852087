import React, { ReactElement } from 'react';
import { Container, Button } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import SvgPlus from './Svg/SvgPlus';
import { notifier } from '../helper/utils';
import { useCreateAccount } from '../helper/customHooks';

interface Props {
  title: string;
}

const Header = ({ title }: Props): ReactElement => {
  const { createAccount } = useCreateAccount();
  const handleCreateAccount = async (): Promise<void> => {
    await notifier().displayForm({
      title: 'Create a new Account',
      inputType: 'text',
      position: 'center',
      confirmButtonText: 'Add Account',
      cb: async (result) => {
        return createAccount(result.value);
      },
    });
  };
  return (
    <header className="navbar navbar-light navbar-expand-lg">
      <Container fluid>
        <Link className="navbar-brand" to="/">
          <h1 className="d-none-navbar-horizontal">Octo - {title}</h1>
        </Link>
      </Container>
      <Container fluid className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav">
          <NavLink className="nav-item nav-link" activeClassName="active" exact to="/">
            Home
          </NavLink>
          <NavLink className="nav-item nav-link" activeClassName="active" exact to="/connection">
            Connection
          </NavLink>
          <NavLink className="nav-item nav-link" to="/accounts">
            Accounts
          </NavLink>
          <Button className="btn add-account-btn" onClick={handleCreateAccount}>
            {' '}
            <SvgPlus size={16} /> Account
          </Button>
        </ul>
      </Container>
    </header>
  );
};

export default Header;
