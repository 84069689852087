import { ChangeEvent, FormEvent, ReactElement, useState } from 'react';
import { Form } from 'react-bootstrap';
import SvgSearch from '../../Svg/SvgSearch';

interface Props {
  onSubmit: (value: string) => void;
  onStateChange: (state: string) => void;
}

const TIMEOUT = 2000;

const Search = ({ onSubmit, onStateChange }: Props): ReactElement => {
  const [timer, setTimer] = useState<number | null>(null);
  const [value, setValue] = useState('');

  const clearTimer = (_timer: number | null) => {
    if (_timer !== null) {
      window.clearTimeout(_timer);
      setTimer(null);
    }
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: _value } = event.target;

    onStateChange(_value)

    setValue(_value);
    clearTimer(timer);

    const _timer = window.setTimeout(() => {
      onSubmit(_value);
      clearTimer(_timer);
    }, TIMEOUT);

    setTimer(_timer);
  };
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(value);
    clearTimer(timer);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <SvgSearch size={20} />
          </span>
        </div>
        <input
          className="form-control"
          onChange={handleChange}
          placeholder="Search..."
          type="text"
          style={{maxWidth: '200px'}}
        />
      </div>
    </Form>
  );
};

export default Search;
