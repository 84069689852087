import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  defaultVal: string;
  label: string;
}

const DatePicker = ({ name, defaultVal, label }: Props): ReactElement => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <span>
        <label className="form-label ">{label}</label>
      </span>

      <div className="input-icon mb-1">
        <input
          style={{ colorScheme: 'dark' }}
          className={`form-control ${errors[name] ? ' is-invalid' : ''}`}
          type="date"
          defaultValue={defaultVal}
          {...register(name)}
          autoComplete="off"
        />
      </div>
    </>
  );
};

export default DatePicker;
