/* eslint-disable @typescript-eslint/no-explicit-any */
import Swal, { SweetAlertInput, SweetAlertPosition, SweetAlertIcon } from 'sweetalert2';

export interface IFeedback {
  text: string;
  icon?: SweetAlertIcon;
}

export interface IDisplayFormOption {
  cb: (arg: any) => Promise<IFeedback>;
  title: string;
  inputType: SweetAlertInput;
  position: SweetAlertPosition;
  confirmButtonText: string;
}

interface INotifier {
  displayForm: (c: IDisplayFormOption) => Promise<void>;
}

export const notifier = (): INotifier => {
  const displayForm = async (c: IDisplayFormOption): Promise<void> => {
    const {
      title = 'Create a new Account',
      inputType = 'text',
      position = 'top-right',
      confirmButtonText = 'Add Account',
    } = c;
    const result = await Swal.fire({
      customClass: {
        closeButton: 'btn btn-secondary',
        cancelButton: 'btn btn-secondary',
        confirmButton: 'btn add-account-btn',
      },
      buttonsStyling: false,
      title,
      input: inputType,
      inputAttributes: {
        autocapitalize: 'off',
      },
      position,
      showCancelButton: true,
      confirmButtonText,
      showLoaderOnConfirm: true,
      preConfirm: (inputText) => {
        return inputText;
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
    if (result.isConfirmed) {
      const feedback = await c.cb(result);
      Swal.fire({
        customClass: {
          confirmButton: 'btn add-account-btn',
        },
        buttonsStyling: false,
        title: feedback.text || 'Account Created',
        icon: feedback.icon || 'success',
      });
    }
  };

  return {
    displayForm,
  };
};
