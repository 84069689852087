import { Connection } from '../../types/Connection';
import { AccountConnectionListAction, AccountConnectionListActionType } from './actions';

export interface AccountConnectionListState {
  data: Connection[];
}
export const accountConnectionListInitialState: AccountConnectionListState = {
  data: [],
};

function accountConnectionListReducer(
  state: AccountConnectionListState = accountConnectionListInitialState,
  action: AccountConnectionListAction,
): AccountConnectionListState {
  switch (action.type) {
    case AccountConnectionListActionType.SetAccountConnectionList:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
}

export default accountConnectionListReducer;
