import * as R from 'ramda';
import { useCallback } from 'react';

import { useAppendList, useSetList } from './actions';
import { RequestHandlerParams, useRequestHandler } from '../api/api';
import endpoints, { GOD_TOKEN } from '../../consts/api';
import { RequestList } from '../../types/Request';

export interface FetchListArgs {
  append?: boolean;
  limit?: number;
  created?: string;
  product_id?: string;
  connection_id?: string;
  connection_name?: string;
  connection_channel?: string;
  env?: string;
  action?: string;
  connection_backend?: string;
  account_id?: string;
  search?: string;
  status?: string;
  subrequests?: string;
  date_from?: string;
  date_to?: string;
  service?: string;
}

export interface UseFetchList {
  fetchList: (args?: FetchListArgs) => void;
  isLoading: boolean;
}

export const useFetchList = (): UseFetchList => {
  const appendList = useAppendList();
  const setList = useSetList();
  const { isLoading, handleRequest } = useRequestHandler<RequestList[]>();

  const fetchList = useCallback(
    async (args?: FetchListArgs) => {
      const urlParams: FetchListArgs = {};
      const append = args?.append ?? false;
      let token = '';

      if (!R.isNil(args?.limit) && !R.isEmpty(args?.limit)) {
        urlParams.limit = args?.limit;
      }

      if (!R.isNil(args?.created) && !R.isEmpty(args?.created)) {
        urlParams.created = args?.created;
      }

      if (!R.isNil(args?.connection_id) && !R.isEmpty(args?.connection_id)) {
        urlParams.connection_id = args?.connection_id;
      }

      if (!R.isNil(args?.connection_name) && !R.isEmpty(args?.connection_name)) {
        urlParams.connection_name = args?.connection_name;
      }

      if (!R.isNil(args?.connection_channel) && !R.isEmpty(args?.connection_channel)) {
        urlParams.connection_channel = args?.connection_channel;
      }

      if (!R.isNil(args?.connection_backend) && !R.isEmpty(args?.connection_backend)) {
        urlParams.connection_backend = args?.connection_backend;
      }

      if (!R.isNil(args?.env) && !R.isEmpty(args?.env)) {
        urlParams.env = args?.env;
      }

      if (!R.isNil(args?.action) && !R.isEmpty(args?.action)) {
        urlParams.action = args?.action;
      }

      if (!R.isNil(args?.search) && !R.isEmpty(args?.search)) {
        urlParams.search = args?.search;
      }

      if (!R.isNil(args?.account_id) && !R.isEmpty(args?.account_id)) {
        token = args?.account_id ?? '';
      } else {
        token = GOD_TOKEN;
      }

      if (!R.isNil(args?.status) && !R.isEmpty(args?.status)) {
        urlParams.status = args?.status;
      }

      if (!R.isNil(args?.subrequests) && !R.isEmpty(args?.subrequests)) {
        urlParams.subrequests = args?.subrequests;
      }

      if (!R.isNil(args?.product_id) && !R.isEmpty(args?.product_id)) {
        urlParams.product_id = args?.product_id;
      }

      if(!R.isNil(args?.service) && !R.isEmpty(args?.service)) {
        urlParams.service = args?.service;
      }
      
      if (!R.isNil(args?.date_from) && !R.isEmpty(args?.date_from)) {
        if (args?.date_to === '') {
          urlParams.date_from = `${args?.date_from.split('T')[0]}T00:00:00.000Z`;
          urlParams.date_to = `${args?.date_from.split('T')[0]}T23:59:59.999Z`;
        } else {
          urlParams.date_from = args?.date_from;
        }
      }
      
      if (!R.isNil(args?.date_to) && !R.isEmpty(args?.date_to)) {
        urlParams.date_to = args?.date_to;
      }
      
      const params: RequestHandlerParams = {
        method: 'GET',
        params: urlParams,
        token,
        url: `${endpoints.REQUESTS}`,
      };
      const list = await handleRequest(params);

      if (!R.isNil(list)) {
        if (append) {
          appendList(list);
        } else {
          setList(list);
        }
      }
    },
    [appendList, handleRequest, setList],
  );

  return { fetchList, isLoading };
};
