import * as R from 'ramda';
import { ReactElement } from 'react';
import { Accordion, Badge, Col } from 'react-bootstrap';

import GetDetail from './GetDetail';
import { SubRequest } from '../../../types/Detail';
import { RequestDetail } from '../../../types/Request';

interface Props {
  id: string;
  value: SubRequest;
  detail: RequestDetail | null;
}

const SubRequestAccordion = ({ id, value, detail }: Props): ReactElement => {
  const { request, response } = value;
  const { method, url } = request;
  const { duration, headers, status } = response;
  const statusVariant = status >= 300 ? 'badge-danger' : 'badge-success';
  const statusText = headers['status'] || status;
  const path = !R.isEmpty(url) ? new URL(url).pathname : '';

  return (
      <Accordion.Item eventKey={id}>
        <Accordion.Button className='p-2'>
              <div className='badge badge-bordered'>
              {method}
              &nbsp;
              {path}
              </div>
              &nbsp;
              <div className={"badge badge-bordered " + statusVariant}>
                {statusText}
              </div>
            <Col className='text-end me-3'>
              <Badge className="duration" pill>{duration}s</Badge>
            </Col>
        </Accordion.Button>
      <Accordion.Body className='p-0'>
        <GetDetail request={request} response={response} isSubrequest={true} detail={detail} />
      </Accordion.Body>
      </Accordion.Item>
  );
};

export default SubRequestAccordion;
