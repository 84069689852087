import { useEffect, useRef, useState } from "react";
import { Button, Col, ListGroup, ListGroupItem, Overlay, Spinner, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Highlighter from "../../components/Highlighter";
import { useAccountConnectionFetchList } from "../../services/accountConnectionList/api";
import { useAccountConnectionList } from "../../services/selectors";
import { Connection } from "../../types/Connection";

const ConnectionDetails = () => {
  const { pathname } = useLocation();
  const { isLoading: isLoadingConnections, fetchAccountConnectionList } = useAccountConnectionFetchList();
  const { data: connectionsList } = useAccountConnectionList();

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const connectionId = pathname.split('/')[2];

  const [connection, setConnection] = useState<Connection>();

  useEffect(() => {
    fetchAccountConnectionList([connectionId])
  }, []);

  useEffect(() => {
    const connection = connectionsList.find(connection => connection.id === connectionId)
    if (connection) {
      setConnection(connection)
    }
  }, [connectionsList])

  const handleCopyClick = () => {
    setShow(true)
    if (connection) {
      navigator.clipboard.writeText(JSON.stringify(connection, null, 2))
    }
  };

  const handleCopyHide = () =>{
    setShow(false)
  }

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          <h1>Connection Details</h1>
            {isLoadingConnections ? (
              <div className="d-flex justify-content-center mt-4">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              connection ? (
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">{connection.name}</h2>
                        <ListGroup>
                          <ListGroupItem className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div className="fw-bold">ConnectionId</div>
                              {connection.id}
                            </div>
                          </ListGroupItem>
                        </ListGroup>
                        <ListGroupItem>
                          <Col className="d-flex justify-content-end position-absolute end-0 mx-3">
                            <Button ref={target} onClick={handleCopyClick}>
                              Copy to clipboard
                            </Button>
                            <Overlay target={target.current} show={show} placement="right" rootClose={true} rootCloseEvent='mousedown' onHide={handleCopyHide}>
                              {(props) => (
                                <Tooltip id="overlay-example" {...props}>
                                  Copied!
                                </Tooltip>
                              )}
                            </Overlay>
                          </Col>
                          <Highlighter json={connection} className="mt-2" />
                        </ListGroupItem>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Connection not found</h2>
                        <p className="card-text">{connectionId}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
              
            )}
        </div>
      </div>
    </div>
  );
};

export default ConnectionDetails;
