import React, { createContext, useReducer, Dispatch, ReactElement } from 'react';
import mainReducer, { State, Action, initialState } from './mainReducer';

export const StateContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const StateProvider = ({ children }: { children: React.ReactElement }): ReactElement => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <StateContext.Provider value={{ state, dispatch }}>{children}</StateContext.Provider>;
};
