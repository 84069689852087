import * as R from 'ramda';
import { ReactElement } from 'react';
import { Accordion, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { DateTime } from 'luxon';

import GetDetail from './GetDetail';
import SubRequestAccordion from './SubRequestAccordion';

import Label from '../../../components/Label';
import { RequestDetail } from '../../../types/Request';
import { HttpRequest, HttpResponse } from '../../../types/Http';

interface Props {
  detail: RequestDetail | null;
  isLoading: boolean;
  onExited: () => void;
  onCloseClick: () => void;
  show: boolean;
}

const RequestModal = ({ detail, isLoading, onExited, onCloseClick, show }: Props): ReactElement => {
  const { action, connection_name, created_at, account_name, res_duration, status, success, subrequests, req_body, req_headers, req_method, req_url, res_body, res_headers, res_status, id } =
    detail ?? {};

  const response: HttpResponse = {
    body: res_body,
    duration: res_duration ?? 0,
    headers: res_headers ?? {},
    status: res_status ?? 0,
  };

  const request: HttpRequest = {
    body: req_body,
    headers: req_headers ?? {},
    method: req_method  ?? '',
    url: req_url ?? '',
  };

  return (
    <Modal onExited={onExited} onHide={onCloseClick} show={show} size="xl">
      <Modal.Header className="py-3 border-0">
        <Row className="w-100 align-items-center">
          <Col xs="auto">
            <Modal.Title>
              {connection_name}
              <br />
              <span className="small text-muted pb-0">{action}</span>
            </Modal.Title>
          </Col>
          <Col xs="auto" className="ms-auto">
            <Label>Account</Label>
            <div>{account_name}</div>
          </Col>
          <Col xs="auto">
            <Label>Status</Label>
            {success ? (
              <div className="text-success">Success</div>
            ) : (
              <div className="text-danger">{status}</div>
            )}
          </Col>
          <Col xs="auto">
            <Label>Duration</Label>
            <div>{res_duration}s</div>
          </Col>
          <Col xs="auto">
            <Label>Date</Label>
            <div>{!R.isNil(created_at) && DateTime.fromISO(created_at).toRelative()}</div>
          </Col>
        </Row>
        <button className="btn-close" onClick={onCloseClick} type="button" />
      </Modal.Header>
      <GetDetail request={request} response={response} detail={detail} />
      {!R.isNil(subrequests) && !R.isEmpty(subrequests) && (
        <>
          <div className="d-flex bg-light">
            <Label className="col px-3 py-2 text-center">Subqueries</Label>
          </div>
          <Accordion flush>
            {subrequests.map((subRequest, index) => (
              <SubRequestAccordion key={index} id={index.toString()} value={subRequest} detail={detail} />
            ))}
          </Accordion>
        </>
      )}
    </Modal>
  );
};

export default RequestModal;
