import { ReactElement, useEffect, useRef } from 'react';
import RequestList from '../../components/RequestList';

const Requests = (): ReactElement => {

  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <div className="content">
      <RequestList showAll={true} />
    </div>
  );
};

export default Requests;
