import { useCallback } from 'react';
import { RequestHandlerParams, useRequestHandler } from '../api/api';
import endpoints, { GOD_TOKEN } from '../../consts/api';
import { useSetAccountList } from './actions';
import { Account } from '../../types/Account';

export interface UseAccountFetchList {
  fetchAccountList: () => void;
  isLoading: boolean;
}

export const useAccountFetchList = (): UseAccountFetchList => {
  const { isLoading, handleRequest } = useRequestHandler<Account[]>();
  const setAccountList = useSetAccountList();

  const fetchAccountList = useCallback(
    async () => {
      try {
        
        const params: RequestHandlerParams = {
          method: 'GET',
          url: `${endpoints.ACCOUNT_LIST}`,
          token: GOD_TOKEN,
        };
        const list = await handleRequest(params);

        if (!list) {
          return setAccountList([]);
        }

        return setAccountList(list);
      } catch (err) {
        return setAccountList([]);
      }
    },
    [handleRequest, setAccountList],
  );

  return { fetchAccountList, isLoading };
};
