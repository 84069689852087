import React, { ReactElement } from 'react';

interface Props {
  attr?: boolean;
  last?: boolean;
  level?: number;
  value: number;
}

const HighlightNumber = ({ attr = false, last = true, level = 0, value }: Props): ReactElement => {
  const spacer = ' '.repeat(level);

  return (
    <>
      {!attr && spacer}
      <span className="hljs-number">{value}</span>
      {!last && ','}
      {'\n'}
    </>
  );
};

export default HighlightNumber;
