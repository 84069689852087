import qs from 'query-string';
import * as R from 'ramda';
import { useState, useCallback } from 'react';

import { useApi } from '../selectors';
import { useSetError } from './actions';
import { Connection } from '../../types/Connection';
import endpoints, { GOD_TOKEN } from '../../consts/api';
import { useSetGodMode, useSetToken } from './actions';

export interface RequestHandlerParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: { [key: string]: any };
  method: 'POST' | 'GET' | 'PATCH' | 'DELETE';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: { [key: string]: any };
  token?: string | null;
  isAccountList?: boolean;
  accountID?: string;
  url: string;
}

interface UseRequestHandler<T> {
  isLoading: boolean;
  handleRequest: (args: RequestHandlerParams) => Promise<T | null>;
}

export const useRequestHandler = <T>(): UseRequestHandler<T> => {
  const setError = useSetError();
  const [isLoading, setLoading] = useState(false);
  const data = useApi();

  const handleRequest = useCallback(
    async ({
      body,
      method,
      params = {},
      accountID,
      token = accountID ? accountID : data.token,
      url,
    }: RequestHandlerParams): Promise<T | null> => {
      setLoading(true);
      setError(null);

      const urlParams = qs.stringify(params);

      try {
        const res = await fetch(R.isEmpty(params) ? url : `${url}?${urlParams}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method: method,
          body: JSON.stringify(body),
        });

        let data;

        const contentType = res.headers.get('content-type');

        if (contentType === 'application/json') {
          data = await res.json();
        } else {
          data = await res.text();
        }

        if (data.error) {
          throw Error(data.errorMessage);
        }

        return data;
      } catch (err) {
        setError(err as Error);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [data.token, setError],
  );

  return { isLoading, handleRequest };
};

export interface UseConnectionIdToSetToken {
  setFetchListToken: (connectionId: string) => void;
  isLoading: boolean;
}

export const useConnectionIdToSetToken = (): UseConnectionIdToSetToken => {
  const { handleRequest, isLoading } = useRequestHandler();
  const setToken = useSetToken();
  const setGodMode = useSetGodMode();

  const setFetchListToken = useCallback(
    async (connectionId: string): Promise<void> => {
      const response = (await handleRequest({
        method: 'GET',
        url: `${endpoints.CONNECTION_DETAILS_BASEURL}/${connectionId}`,
        token: GOD_TOKEN,
      })) as Connection;

      if (response) {
        setGodMode(false);
        setToken(response.accountId);
      }
    },
    [handleRequest, setGodMode, setToken],
  );

  return { setFetchListToken, isLoading };
};
