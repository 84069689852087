import * as R from 'ramda';
import { useContext } from 'react';
import qs from 'query-string';

import { StateContext } from './state/context';
import { ApiState } from '../services/api';
import { useSetGodMode, useSetToken } from '../services/api/actions';
import { ModalState } from '../services/modal';
import { AccountListState } from '../services/accountList';
import { GOD_TOKEN } from '../consts/api';
import { ListState } from './list';
import { BookingsListState } from './bookings';
import { MetaDataState } from './metadata';
import { AccountConnectionListState } from './accountConnectionList';

export const useApi = (): ApiState => useContext(StateContext).state.api;
export const useList = (): ListState => useContext(StateContext).state.list;
export const useModal = (): ModalState => useContext(StateContext).state.modal;
export const useAccountList = (): AccountListState => useContext(StateContext).state.accountList;
export const useBookingsList = (): BookingsListState => useContext(StateContext).state.bookings;
export const useMetaData = (): MetaDataState => useContext(StateContext).state.metaData;
export const useAccountConnectionList = (): AccountConnectionListState => useContext(StateContext).state.accountConnectionList; 

type UseInit = () => void;

export const useInit = (): UseInit => {
  const api = useApi();
  const setGodMode = useSetGodMode();
  const setToken = useSetToken();

  return () => {
    if (!R.isEmpty(api.token)) {
      return;
    }

    const parsed = qs.parse(window.location.search);

    if (R.isNil(parsed?.token)) {
      setGodMode(true);
      setToken(GOD_TOKEN);
    } else {
      setGodMode(false);
      setToken(String(parsed.token));
    }
  };
};
