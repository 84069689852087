import { ReactElement, RefObject, useState } from 'react';
import { DateTime } from 'luxon';
import { RequestList } from '../../../types/Request';

interface Props {
  godMode?: boolean;
  onClick: (id: string, token: string | null) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refProp?: RefObject<any> | ((node?: Element | null) => void) | null;
  value: RequestList;
  vertical?: boolean;
}

const RequestRow = ({ godMode = false, onClick, refProp = null, value, vertical }: Props): ReactElement => {
  const { account_name, action, connection_name, connection_channel, account_id, created_at, id, res_duration, status, success, environment } = value;
  const [brkPnt, _setBrkPnt] = useState(window.innerWidth)

  const token = account_id;
  const handleClick = () => {
    onClick(id, token);
  }
  return (
    brkPnt <= 768 ? (
      <>
        {!godMode ? null : (
          <tr className="cursor-pointer" onClick={handleClick} ref={refProp}>
            <th>Account</th>
            <td>{account_name}</td>
          </tr>
        )}
        <tr className="cursor-pointer" onClick={handleClick} ref={refProp}>
          <th>Connection</th>
          <td>{connection_name}</td>
        </tr>
        <tr className="cursor-pointer" onClick={handleClick} ref={refProp}>
          <th>Channel</th>
          <td>{connection_channel}</td>
        </tr>
        <tr className="cursor-pointer" onClick={handleClick} ref={refProp}>
          <th>Action</th>
          {success ? (
            <td className="text-success">Success</td>
          ) : (
            <td className="text-danger">{status}</td>
          )}
        </tr>
        <tr className="cursor-pointer" onClick={handleClick} ref={refProp}>
          <th>Status</th>
          <td>{status}</td>
        </tr>
        <tr className="cursor-pointer" onClick={handleClick} ref={refProp}>
          <th>Duration</th>
          <td>{res_duration}</td>
        </tr>
        <tr className="cursor-pointer" onClick={handleClick} ref={refProp}>
          <th>Duration</th>
          <td>{res_duration}</td>
        </tr>
        <tr className="cursor-pointer" onClick={handleClick} ref={refProp}>
          <th>Date</th>
          <td>{DateTime.fromISO(created_at).toRelative()}</td>
        </tr>
        <tr className="cursor-pointer" onClick={handleClick} ref={refProp}>
          <th>Date</th>
          <td>{created_at}</td>
        </tr>
        <tr className="cursor-pointer" onClick={handleClick} ref={refProp}>
          <th>ENV</th>
          <td>{environment}</td>
        </tr>
        <tr>
          <td colSpan={2} className='table-info'></td>
        </tr>
      </>
    ) : (
      <tr className="cursor-pointer" onClick={handleClick} ref={refProp}>
        {!godMode ? null : <td>{account_name}</td>}
        <td>{connection_name}</td>
        <td>{connection_channel}</td>
        <td>{action}</td>
        {success ? (
          <td className="text-success">Success</td>
        ) : (
          <td className="text-danger">{status}</td>
        )}
        <td>{res_duration}s</td>
        <td>{DateTime.fromISO(created_at).toRelative()}</td>
        <td>{created_at}</td>
        <td>{environment}</td>
      </tr>
    )
    
  );
};

export default RequestRow;
