import { Account } from '../../types/Account';
import { AccountListAction, AccountListActionType } from './actions';

export interface AccountListState {
  data: Account[];
}
export const accountListInitialState: AccountListState = {
  data: [],
};

function accountListReducer(
  state: AccountListState = accountListInitialState,
  action: AccountListAction,
): AccountListState {
  switch (action.type) {
    case AccountListActionType.AddAccount:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case AccountListActionType.SetAccountList:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
}

export default accountListReducer;
