import { ModalAction, ModalActionType } from './actions';
import { RequestDetail } from '../../types/Request';

export interface ModalState {
  detail: RequestDetail | null;
  show: boolean;
}

export const modalInitialState: ModalState = {
  detail: null,
  show: false,
};

function modalReducer(state: ModalState = modalInitialState, action: ModalAction): ModalState {
  switch (action.type) {
    case ModalActionType.SetDetail:
      return {
        ...state,
        detail: action.payload,
      };
    case ModalActionType.SetShow:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
}

export default modalReducer;
