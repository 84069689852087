import { ReactElement, useCallback, useMemo, useState } from 'react';
import { Card, Container, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useApi, useList, useModal } from '../../services/selectors';
import { FilterState } from '../../types/Request';
import RequestFilter from './components/RequestFilter';
import RequestModal from './components/RequestModal';
import Search from './components/Search';
import { UseRequestHook } from './services/hooks';
import qs from 'query-string';
import RequestRow from './components/RequestRow';
import { useSetDetail, useSetShow } from '../../services/modal/actions';
import { useEmptyList } from '../../services/list/actions';
import RequestRowPlaceholder from './components/RequestRowPlaceholder';
import RequestModalPlaceholder from './components/RequestModalPlaceholder';

interface Props {
  showAll: boolean;
  accountId?: string;
}

const RequestList = ({ showAll, accountId }: Props): ReactElement => {
  const { godMode } = useApi();
  const history = useHistory();
  const {
    fetchDetail,
    fetchList,
    isLoadingDetail,
    isLoadingList,
    lastRowRef,
    filterInput,
    setFilterInput,
    searchInput,
    setSearchInput,
  } = UseRequestHook(showAll, accountId);
  const { data, listComplete } = useList();
  const { detail, show } = useModal();
  const setDetail = useSetDetail();
  const setShow = useSetShow();
  const emptyList = useEmptyList();
  const [brkPnt, _setBrkPnt] = useState(window.innerWidth)

  const handleFilterChange = async (state: FilterState) => {
    setFilterInput(state);
  }

  const filterToHistory = () => {
    history.push('')
    let params = {};
    Object.entries(filterInput).map(filter => {
      if (filter[1]) {
        params = {
          ...params,
          [filter[0]]: filter[1]
        }
      }
    })
    history.push(`?${qs.stringify(params)}`)
  };

  const handleFilterSubmit = async (filter: FilterState) => {
    emptyList();
    if (showAll) {
      filterToHistory();
    }
    if (showAll) {
      setFilterInput(filter)
      fetchList(filterInput);
    } else {
      setFilterInput({
        ...filter,
        account_id: accountId ?? ''
      })
      fetchList({
        ...filterInput,
        account_id: accountId ?? '',
      });
    }
  };

  const handleFilterReset = async () => {
    emptyList();
    if (showAll) {
      history.push('');
      const newFilter = {
        account_id: '',
        product_id: '',
        connection_id: '',
        connection_channel: '',
        action: '',
        env: '',
        connection_backend: '',
        status: '',
        subrequests: '',
        date_from: '',
        date_to: '',
        service: ''
      };
      setFilterInput(newFilter)
      fetchList(newFilter);
    } else {
      const newFilter = {
        account_id: accountId ?? '',
        product_id: '',
        connection_id: '',
        connection_channel: '',
        action: '',
        env: '',
        connection_backend: '',
        status: '',
        subrequests: '',
        date_from: '',
        date_to: '',
        service: ''
      };
      setFilterInput(newFilter)
      fetchList(newFilter);
    }
  };

  const handleSearch = async (value: string) => {
    history.push('')
    setSearchInput(value)
    if (searchInput) {
      emptyList();
      history.push(`?search=${searchInput}`)
      fetchList({ search: searchInput });
    }
  };

  const handleSearchChange = async (state: string) => {
    setSearchInput(state);
  };
  
  const handleClick = useCallback((id: string) => {
    if (showAll) {
      history.push(`?detail=${id}`);
    }
    fetchDetail(id);
  }, [fetchDetail, history, setShow]);

  const List = useMemo(() => {
    return data.map((request, i) => {
      const isLast = i === data.length - 1
      const shouldAddLastRowRef = isLast;
      return <RequestRow
        godMode={godMode}
        key={request.id}
        onClick={handleClick}
        refProp={shouldAddLastRowRef ? lastRowRef : null}
        value={request}
    />
    });
  }, [godMode, handleClick, lastRowRef, data]);

  const handleCloseClick = () => {
    setShow(false);
  };

  const handleExited = () => {
    if (showAll) {
      history.push('')
      let params = {};
      Object.entries(filterInput).map(filter => {
        if (filter[1]) {
          params = {
            ...params,
            [filter[0]]: filter[1]
          }
        }
      })
      history.push(`?${qs.stringify(params)}`)
    } 
    setDetail(null);
  };
  
  return (
    <Container fluid>
      <div className="card-body d-md-flex">
        <div className='d-flex'>
        <RequestFilter
          godmode={showAll}
          onStateChange={handleFilterChange}
          onSubmit={handleFilterSubmit}
          onReset={handleFilterReset}
          state={filterInput}
        />
        </div>
        {showAll ? (
          <div className='d-flex' style={{minWidth: '200px'}}>
            <Search
            onSubmit={handleSearch}
            onStateChange={handleSearchChange}
            /> 
          </div>
        ) : (
          <></>
        )}
      </div>
        <Card>
          <Table hover className='mb-0' style={{tableLayout: 'fixed'}}>
            {brkPnt > 768 && (
              <thead>
                <tr>
                  {!godMode ? null : <th style={{width: '140%'}}>Account</th>}
                  <th style={{width: '140%'}}>Connection</th>
                  <th style={{width: '100%'}}>Channel</th>
                  <th style={{width: '100%'}}>Action</th>
                  <th style={{width: '80%'}}>Status</th>
                  <th style={{width: '40%'}}>Duration</th>
                  <th style={{width: '100%'}}>Date</th>
                  <th style={{width: '100%'}}>Date</th>
                  <th style={{width: '80%', whiteSpace: 'nowrap'}}>ENV</th>
                </tr>
              </thead>
            )}
            <tbody>
              {List}
              {isLoadingList && (
                <RequestRowPlaceholder />
              )}
            </tbody>
          </Table>
        </Card>
        {show && detail ? (
          <RequestModal
            detail={detail}
            isLoading={isLoadingDetail}
            onExited={handleExited}
            onCloseClick={handleCloseClick}
            show={show}
          />
        ) : (
          <RequestModalPlaceholder
          show={show}
          />
        )}
      </Container>
  );
};

export default RequestList;
