import React, { ReactElement } from 'react';

import { HttpHeaderValue } from '../../../types/Http';

interface Props {
  attr: string;
  value: HttpHeaderValue;
}

const HighlightHeadersLine = ({ attr, value }: Props): ReactElement => {
  return (
    <>
      <span className="hljs-attribute">{attr}</span>
      {': '}
      {value}
      {'\n'}
    </>
  );
};

export default HighlightHeadersLine;
