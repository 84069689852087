import { ReactElement } from 'react';
import { Col, Placeholder, Row } from 'react-bootstrap';

interface Props {
  godMode?: boolean;
  rows?: number;
}

const RequestRowPlaceholder = ({ godMode = false, rows = 30}: Props): ReactElement => {
  const getRandomWidth = () => {
    return Math.floor(Math.random() * (8 - 2) + 2)
  }
  const placeholder = Array.from(Array(rows).keys()).map(i => {
    return (
      <tr key={i}>
        {!godMode ? null : <td><Placeholder as={Col} animation="glow"><Placeholder xs={getRandomWidth()} /></Placeholder></td>}
        <td><Placeholder as={Col} animation="glow"><Placeholder xs={getRandomWidth()} /></Placeholder></td>
        <td><Placeholder as={Col} animation="glow"><Placeholder xs={getRandomWidth()} /></Placeholder></td>
        <td><Placeholder as={Col} animation="glow"><Placeholder xs={getRandomWidth()} /></Placeholder></td>
        <td><Placeholder as={Col} animation="glow"><Placeholder xs={getRandomWidth()} /></Placeholder></td>
        <td><Placeholder as={Col} animation="glow"><Placeholder xs={5} /></Placeholder></td>
        <td><Placeholder as={Col} animation="glow"><Placeholder xs={5} /></Placeholder></td>
        <td><Placeholder as={Col} animation="glow"><Placeholder xs={7} /></Placeholder></td>
        <td><Placeholder as={Col} animation="glow"><Placeholder xs={7} /></Placeholder></td>
        <td><Placeholder as={Col} animation="glow"><Placeholder xs={7} /></Placeholder></td>
      </tr>
    )
  })
  return (
    <>
      {placeholder}
    </>
    );
};

export default RequestRowPlaceholder;
