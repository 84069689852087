import { MetaDataAction, MetaDataActionType } from './actions';

export interface MetaDataState {
  data: any;
}
export const metaDataInitialState: MetaDataState = {
  data: null,
};

function metaDataReducer(
  state: MetaDataState = metaDataInitialState,
  action: MetaDataAction,
): MetaDataState {
  switch (action.type) {
    case MetaDataActionType.SetMetaData:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
}

export default metaDataReducer;
