const ROOT = 'https://api.octo.travel';

export const GOD_TOKEN = '48a44448-3f0c-4671-be9d-6ee353ecb4bb';

const endpoints = {
  REQUESTS: `${ROOT}/account/requestsv2`,
  CONNECTION_DETAILS_BASEURL: `${ROOT}/account/connection`,
  ACCOUNT_LIST: `${ROOT}/accounts`,
  CREATE_ACCOUNT: `${ROOT}/account`,
  ACCOUNT_BOOKINGS: `${ROOT}/account/booking/count`,
  METADATA: `${ROOT}/account/meta`,
};

export default endpoints;
