import { ReactElement } from 'react';
import { Account } from '../../types/Account';
import AccountLink from './../Account/AccountLink';

interface Props {
  account: Account;
  index: number;
  vertical: boolean;
}

const TableData = ({ account, index, vertical }: Props): ReactElement => {
  return vertical ? (
    <>
      <tr key={`${account.id}_index`}>
        <th>#</th>
        <td>{index}</td>
      </tr>
      <tr key={`${account.id}_id`}>
        <th>ID</th>
        <td>
          <AccountLink account={account}>{account.id}</AccountLink>
        </td>
      </tr>
      <tr key={`${account.id}_name`}>
        <th>
          NAME
        </th>
        <td>
          <AccountLink account={account}>{account.name}</AccountLink>{' '}
        </td>
      </tr>
      <tr>
        <div className='border'></div>
      </tr>
    </>
  ) : (
    <tr key={account.id}>
      <td>{index}</td>
      <td>
        <AccountLink account={account}>{account.id}</AccountLink>
      </td>
      <td>
        <AccountLink account={account}>{account.name}</AccountLink>{' '}
      </td>
    </tr>
  );
};

export default TableData;
