import { useDispatch } from '../state/dispatch';

export enum MetaDataActionType {
  SetMetaData = 'SET_METADATA',
}

interface DisplayMetaData {
  type: MetaDataActionType.SetMetaData;
  payload: any;
}

type UseSetMetaData = (metaData: any) => void;

export const useSetMetaData = (): UseSetMetaData => {
  const dispatch = useDispatch();

  return (metaData) => {
    return dispatch({
      type: MetaDataActionType.SetMetaData,
      payload: metaData,
    });
  };
};

export type MetaDataAction = DisplayMetaData;
