import modalReducer, { ModalState, modalInitialState } from '../modal';
import { ModalAction } from '../modal/actions';
import apiReducer, { ApiState, apiInitialState } from '../api';
import { ApiAction } from '../api/actions';
import listReducer, { ListState, listInitialState } from '../list';
import { ListAction } from '../list/actions';
import accountListReducer, { AccountListState, accountListInitialState } from '../accountList';
import { AccountListAction } from '../accountList/actions';
import { BookingListAction } from '../bookings/actions';
import bookingsReducer, { bookingsListInitialState, BookingsListState } from '../bookings';
import { MetaDataAction } from '../metadata/actions';
import metaDataReducer, { metaDataInitialState, MetaDataState } from '../metadata';
import { AccountConnectionListAction } from '../accountConnectionList/actions';
import accountConnectionListReducer, { accountConnectionListInitialState, AccountConnectionListState } from '../accountConnectionList';

export type Action = ApiAction | ListAction | ModalAction | AccountListAction | BookingListAction | MetaDataAction | AccountConnectionListAction;

export interface State {
  api: ApiState;
  list: ListState;
  modal: ModalState;
  accountList: AccountListState;
  bookings: BookingsListState;
  metaData: MetaDataState;
  accountConnectionList: AccountConnectionListState;
}

export const initialState = {
  api: apiInitialState,
  list: listInitialState,
  modal: modalInitialState,
  accountList: accountListInitialState,
  bookings: bookingsListInitialState,
  metaData: metaDataInitialState,
  accountConnectionList: accountConnectionListInitialState,
};

const mainReducer = ({ api, list, modal, accountList, bookings, metaData, accountConnectionList }: State, action: Action): State => ({
  api: apiReducer(api, action as ApiAction),
  list: listReducer(list, action as ListAction),
  modal: modalReducer(modal, action as ModalAction),
  accountList: accountListReducer(accountList, action as AccountListAction),
  bookings: bookingsReducer(bookings, action as BookingListAction),
  metaData: metaDataReducer(metaData, action as MetaDataAction),
  accountConnectionList: accountConnectionListReducer(accountConnectionList, action as AccountConnectionListAction),
});

export default mainReducer;
