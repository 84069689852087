import { ReactElement } from 'react';
import { Modal, Spinner } from 'react-bootstrap';

interface Props {
  show: boolean;
}

const RequestModalPlaceholder = ({ show }: Props): ReactElement => {

  return (
    <Modal show={show} size="xl">
      <div className="text-center my-3">
        <Spinner animation="border" variant="primary" />
      </div>
    </Modal>
  );
};

export default RequestModalPlaceholder;
