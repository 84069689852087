import { useCallback } from 'react';

import { useDispatch } from '../state/dispatch';
import { RequestList } from '../../types/Request';

export enum ListActionType {
  AppendList = 'APPEND_LIST',
  EmptyList = 'EMPTY_LIST',
  SetList = 'SET_LIST',
  SetListComplete = 'SET_LIST_COMPLETE',
}

interface AppendList {
  type: ListActionType.AppendList;
  payload: RequestList[];
}

type UseAppendList = (list: RequestList[]) => void;

export const useAppendList = (): UseAppendList => {
  const dispatch = useDispatch();

  return useCallback(
    (list: RequestList[]) =>
      dispatch({
        type: ListActionType.AppendList,
        payload: list,
      }),
    [dispatch],
  );
};

interface EmptyList {
  type: ListActionType.EmptyList;
}

type UseEmptyList = () => void;

export const useEmptyList = (): UseEmptyList => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch({
      type: ListActionType.EmptyList,
    });
  }, [dispatch]);
};
interface SetListComplete {
  type: ListActionType.SetListComplete;
  payload: boolean;
}

type UseSetListComplete = (isComplete: boolean) => void;

export const useSetListComplete = (): UseSetListComplete => {
  const dispatch = useDispatch();

  return useCallback((isComplete: boolean) => {
    dispatch({
      type: ListActionType.SetListComplete,
      payload: isComplete,
    });
  }, [dispatch]);
};

interface SetList {
  type: ListActionType.SetList;
  payload: RequestList[];
}

type UseSetList = (list: RequestList[]) => void;

export const useSetList = (): UseSetList => {
  const dispatch = useDispatch();

  return useCallback(
    (list: RequestList[]) =>
      dispatch({
        type: ListActionType.SetList,
        payload: list,
      }),
    [dispatch],
  );
};

export type ListAction = AppendList | EmptyList | SetList | SetListComplete;
