import { useCallback, useState } from 'react';
import { RequestHandlerParams, useRequestHandler } from '../api/api';
import endpoints, { GOD_TOKEN } from '../../consts/api';
import { useSetAccountConnectionList } from './actions';
import { Connection } from '../../types/Connection';

export interface UseAccountConnectionFetchList {
  fetchAccountConnectionList: (connectionIds: string[]) => void;
  isLoading: boolean;
}

export const useAccountConnectionFetchList = (): UseAccountConnectionFetchList => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleRequest } = useRequestHandler<Connection>();
  const setAccountConnectionList = useSetAccountConnectionList();

  const fetchAccountConnectionList = useCallback(
    async (connectionIds: string[]) => {
      try {
        setIsLoading(true);
        const connectionList = (await Promise.all(connectionIds.map(async connectionId => {
          const params: RequestHandlerParams = {
            method: 'GET',
            url: `${endpoints.CONNECTION_DETAILS_BASEURL}/${connectionId}`,
            token: GOD_TOKEN,
          };
          return await handleRequest(params)
        }))).filter(Boolean) as Connection[];

        setIsLoading(false);

        return setAccountConnectionList(connectionList);
      } catch (err) {
        return setAccountConnectionList([]);
      }
    },
    [handleRequest, setAccountConnectionList],
  );

  return { fetchAccountConnectionList, isLoading };
};
