import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Account } from '../../types/Account';

interface Props {
  children?: React.ReactNode;
  account: Account;}

const AccountLink = ({ account, children }: Props): ReactElement => {
  return (
    <Link
        style={{color: 'inherit'}}
          to={{
            pathname: `/accounts/${account.id}`          }}
        >
      {children}
    </Link>
  );
};

export default AccountLink;
