import qs from 'query-string';
import * as R from 'ramda';
import { ReactElement, useState } from 'react';
import { Col, Nav, Tab } from 'react-bootstrap';

import Highlighter from '../../Highlighter';
import Label from '../../Label';
import { HttpRequest, HttpResponse } from '../../../types/Http';
import SvgDownload from '../../Svg/SvgDownload';
import { RequestDetail } from '../../../types/Request';

interface Props {
  request?: HttpRequest;
  response?: HttpResponse;
  isSubrequest?: boolean;
  detail: RequestDetail | null;
}

const getDefaultTab = (isQuery: boolean, isBody: boolean): string => {
  if (isQuery) {
    return 'query';
  } else if (isBody) {
    return 'body';
  } else {
    return 'headers';
  }
};

const GetDetail = ({ request, response, isSubrequest, detail }: Props): ReactElement => {
  const [copyShow, setCopyShow] = useState(false);

  const handleCopyClick = () => {
    setCopyShow(true)
    if (request) {
      const headersToString = () => {
        if (request.headers) {
          return Object.entries(request.headers).map(([key, value]) => `-H '${key}: ${value}'`).join(' ');
        }
        return '';
      };

      const curl = `curl -X ${request.method} ${headersToString()} ${request.body? (`-d '${JSON.stringify(request.body)}'`) : ("")} ${request.url}`
      navigator.clipboard.writeText(curl)
      }

    setTimeout(() => {
      setCopyShow(false)
    }, 2000);
  };

  const handleDownloadClick = () => {
    if (request && response && detail) {
      const deprRequest = JSON.stringify(request.body, null, 4).replaceAll(/"ApiKey": "[^"]*",/g, '"ApiKey": "***********"');
      const deprResponse = JSON.stringify(response.body, null, 4).replaceAll(/"ApiKey": "[^"]*",/g, '"ApiKey": "***********"');
      const metadata = `Request ID: ${detail.id}\nRequest URL: ${detail.req_url}\nRequest Method: ${detail.req_method}\nResponse Status: ${detail.status}\nResponse Duration: ${detail.res_duration}s\nDate: ${detail.created_at}\n`;
      const octoCapabilities = request.headers['octo-capabilities'] ? `${request.headers['octo-capabilities']}` : 'No capabilities';
      const data = `MAIN REQUEST METADATA:\n${metadata}\nOCTO-CAPABILITIES: ${octoCapabilities}\n${request.body ? `\nREQUEST:\n${deprRequest}\n`: ''}${response.body ? `\nRESPONSE\n${deprResponse}` : ''}`;
      const blob = new Blob([data], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = !isSubrequest ? `request-${detail.id}.txt` : `subrequest-${detail.id}.txt`;
      link.href = url;
      link.click();
    }
  };

  if (R.isNil(request) || R.isNil(response)) {
    return <></>;
  }

  const opts = {
    parseBooleans: true,
    parseNumbers: true,
  };
  const parsed = qs.parseUrl(request.url, opts);
  const showReqBody = !R.isNil(request.body) && request.body !== "";
  const showReqQuery = !R.isEmpty(parsed.query);
  const showResBody = !R.isNil(response.body);
  const reqDefaultKey = 'req-' + getDefaultTab(showReqQuery, showReqBody);
  const resDefaultKey = 'res-' + getDefaultTab(false, showResBody);

  const cUrlButton = () => {
    return (
      <a className={`nav-link cursor-pointer`} onClick={handleCopyClick}>
        {copyShow ? ('Copied!') : ('Copy cUrl')}
      </a>
    );
  }

  return (
    <div className="d-flex">
      <Col xs="6">
        <Tab.Container defaultActiveKey={reqDefaultKey}>
          <Label className="col d-flex header-primary">
            <span className="py-2 mx-3">Request</span>
            <Nav className="mx-auto">
              {showReqQuery && <Nav.Link eventKey="req-query">Query</Nav.Link>}
              {showReqBody && <Nav.Link eventKey="req-body">Body</Nav.Link>}
              <Nav.Link eventKey="req-headers">Headers</Nav.Link>
            </Nav>
            {cUrlButton()}
          </Label>

          <Tab.Content>
            {showReqQuery && (
              <Tab.Pane eventKey="req-query">
                <Highlighter key={parsed.fragmentIdentifier} json={parsed.query} />
              </Tab.Pane>
            )}
            {showReqBody && (
              <Tab.Pane eventKey="req-body">
                <Highlighter key={request.body ? request.body.id : ''} json={request?.body} />
              </Tab.Pane>
            )}
            <Tab.Pane eventKey="req-headers">
              <Highlighter key={request.method} request={request} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
      <Col xs="6" className="bg-lighter">
        <Tab.Container defaultActiveKey={resDefaultKey}>
          <Label className="col px-3 d-flex header-primary">
            <span className="py-2">Response</span>
            <Nav className="mx-auto">
              {showResBody && <Nav.Link eventKey="res-body">Body</Nav.Link>}
              <Nav.Link eventKey="res-headers">Headers</Nav.Link>
            </Nav>
              <a className={`nav-link cursor-pointer`} onClick={handleDownloadClick}>
                  <SvgDownload size={14} />
                </a>
            {/* {isSubrequest && (
              <a className='nav-link cursor-pointer' href={`https://dashboard.ventrata.com/admin/requests?fixed_filters[id]=${response.headers['x-request-id']}&locale=en#dt-row:${response.headers['x-request-id']}`} target='_blank'>
                Show in Ventrata
              </a>
              )} */}
          </Label>

          <Tab.Content>
            {showResBody && (
              <Tab.Pane eventKey="res-body">
                <Highlighter key={request.body ? request.body.id : ''} json={response?.body} />
              </Tab.Pane>
            )}

            <Tab.Pane eventKey="res-headers">
              <Highlighter key={request.body ? request.body.id : ''} response={response} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </div>
  );
};

export default GetDetail;
