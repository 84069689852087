import React, { ReactElement } from 'react';

const Footer = (): ReactElement => {
  return (
    <div className="footer footer-transparent text-center">
      Powered by{' '}
      <a target="_blank" rel="noreferrer" href="https://ventrata.com">
        Ventrata
      </a>
    </div>
  );
};

export default Footer;
