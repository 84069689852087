import { useDispatch } from '../state/dispatch';

export enum BookingsListActionType {
  SetBookings = 'SET_BOOKINGS',
}

interface DisplayBookingsList {
  type: BookingsListActionType.SetBookings;
  payload: any;
}

type UseSetBooking = (booking: any) => void;

export const useSetBookingList = (): UseSetBooking => {
  const dispatch = useDispatch();

  return (booking) => {
    return dispatch({
      type: BookingsListActionType.SetBookings,
      payload: booking,
    });
  };
};

export type BookingListAction = DisplayBookingsList;
