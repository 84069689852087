import { useEffect, useRef, useState } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const ConnectionSearch = () => {
  const mountedRef = useRef(true);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const handleSubmit = () => {
    history.push(`/connection/${searchText}`)
  }

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          <h2>Connection Search</h2>
          <form className="mx-3" onSubmit={handleSubmit}>
            <InputGroup className="input-group">
              <FormControl
                type="text"
                className="form-control"
                name="connection_id"
                onChange={handleChange}
                value={searchText}
                placeholder="Connection Id"
              />
              <div className="input-group-append">
                <button className="btn btn-outline-primary" type="submit">
                  Search
                </button>
              </div>
            </InputGroup>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConnectionSearch;
