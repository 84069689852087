import { ReactElement, useEffect } from 'react';
import * as yup from 'yup';
import { Button, Col, Row } from 'react-bootstrap';
import DatePicker from '../../components/DatePicker';
import Highlighter from '../../components/Highlighter';
import { useBookingFetchList } from '../../services/bookings/api';
import { useBookingsList } from '../../services/selectors';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BookingsSearchParams } from '../../types/Bookings';
import { SelectOptions } from '../../consts/constant';
import _ from 'lodash';
import RadioButton from '../../components/RadioButton';

interface Props {
  accountID: string;
}

export const querySchema: yup.SchemaOf<BookingsSearchParams> = yup.object().shape({
  accountID: yup.string().required(),
  type: yup.string().required(),
  date_end: yup.string().required('This field is required').typeError('This field is required'),
  date_start: yup.string().required('This field is required').typeError('This field is required'),
});

const AccountBooking = ({ accountID }: Props): ReactElement => {
  const { isLoading, fetchBookings, resetBookingsList } = useBookingFetchList();
  const { data } = useBookingsList();
  const methods = useForm<BookingsSearchParams>({
    resolver: yupResolver(querySchema),
    defaultValues: {
      accountID: accountID,
      type: 'JSON',
    },
  });

  useEffect(() => {
    let abortController = new AbortController();
    resetBookingsList();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleSubmit } = methods;

  const now = new Date();
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1).toLocaleDateString('en-CA');
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0).toLocaleDateString('en-CA');

  const onSubmitHandler: SubmitHandler<BookingsSearchParams> = (values) => {
    fetchBookings(values);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <div className="accnt-list-wrapper mb-3 mt-3">
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Row>
              <Col md={9} xs={8}>
                <div className="form-group mt-1">
                  <Row>
                    <Col md={4} xs={8}>
                      <DatePicker name="date_end" defaultVal={firstDay} label="Start date" />
                    </Col>
                    <Col md={4} xs={8}>
                      <DatePicker name="date_start" defaultVal={lastDay} label="End date" />
                    </Col>
                    <Col md={4} xs={8}>
                      <span>
                        <label className="form-label">Type</label>
                      </span>
                      <Row>
                        {_.map(SelectOptions, (value, key) => (
                          <RadioButton group="type" label={value} key={key} />
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={3} xs={4}>
                <Button type="submit" className="mt-4" disabled={isLoading}>
                  Get Bookings
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </FormProvider>

      <div className="row ">
        <div className="col-12 mt-3 ">
          <div className="card">
            <div className="card-body">
              {data ? (
                isLoading ? (
                  <div>Loading...</div>
                ) : Array.isArray(data.bookings) ? (
                  <div>
                    <Highlighter json={data} />
                  </div>
                ) : (
                  <div style={{ overflow: 'scroll', maxHeight: 500 }}>
                    <p>{data}</p>
                  </div>
                )
              ) : (
                <div>
                  <p>No bookings found </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountBooking;
