import { ChangeEvent, FormEvent, ReactElement, useEffect, useState } from 'react';
import { InputGroup, FormControl, Badge, Button, Dropdown, ButtonGroup, ToggleButton, Accordion, Row, Spinner } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { metaDataInitialState } from '../../../services/metadata';
import { useMetaDataFetch } from '../../../services/metadata/api';
import { FilterState, MetaData } from '../../../types/Request';
import { useAccountConnectionList, useAccountList, useApi, useMetaData } from './../../../services/selectors';
import { format, formatISO } from 'date-fns';
import { useAccountFetchList } from '../../../services/accountList/api';
import { Account } from '../../../types/Account';
import * as R from 'ramda';
import { useAccountConnectionFetchList } from '../../../services/accountConnectionList/api';
import { Connection } from '../../../types/Connection';

interface Props {
  godmode: boolean;
  onStateChange: (state: FilterState) => void;
  onSubmit: (filter: FilterState) => void;
  onReset: () => void;
  state: FilterState;
}

const RequestFilter = ({ godmode, onStateChange, onSubmit, onReset, state }: Props): ReactElement => {
  const api = useApi();
  const { fetchMetaData } = useMetaDataFetch();
  const { data: metadata } = useMetaData();
  const [metaData, setMetaData] = useState<MetaData>(metaDataInitialState.data)
  const [filterCount, setFilterCount] = useState(0);
  const [useRangeValue, setUseRangeValue] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const [dateFrom, setDateFrom] = useState<Date | null>();
  const [dateTo, setDateTo] = useState<Date | null>();

  const filterStatus = ['400', '401', '403', '404', '500', '502', '503', '504'];

  const { data: accountList } = useAccountList();
  const { isLoading, fetchAccountList } = useAccountFetchList();
  const [accounts, setAccounts] = useState<Account[]>();

  const [accountSearch, setAccountSearch] = useState('');
  const [connectionSearch, setConnectionSearch] = useState('');

  const { isLoading: isLoadingConnections, fetchAccountConnectionList } = useAccountConnectionFetchList();
  const { data: connectionsList } = useAccountConnectionList();
  const [connections, setConnections] = useState<Connection[]>([]);

  useEffect(() => {
    if (metadata === null) {
      fetchMetaData();
    };
    if (R.isEmpty(accountList)) {
      fetchAccountList();
    }
  }, []);
  
  useEffect(() => {
    setAccounts(accountList)
  }, [accountList])

  useEffect(() => {
    setMetaData(metadata)
  }, [metadata]);

  useEffect(() => {
    if (metaData) {
      if (!metaData.channels.find(channel => channel.name === state.connection_channel)?.actions.includes(state.action)) {
        state.action = '';
      }
    }
  }, [state.connection_channel])

  useEffect(() => {
    if (!useRangeValue) {
      state.date_to = '';
      setDateTo(null)
    }
  }, [state.date_from])

  useEffect(() => {
    if (state.date_from && !dateFrom) {
      setDateFrom(new Date(state.date_from.slice(0, -8)));
    }
    if (state.date_to && !dateTo) {
      setDateTo(new Date(state.date_to.slice(0, -8)));
      setUseRangeValue(true)
    }
    let count = 0;
    if (state.status) {
      count++;
    }
    if (state.connection_backend) {
      count++;
    }
    if (state.env) {
      count++;
    }
    if (state.subrequests) {
      count++
    }
    if (dateFrom) {
      count++;
    }
    if (state.service) {
      count++;
    }
    
    setFilterCount(count);
    if (count > 0) {
      setShowMore(true);
    }
  }, [state, dateFrom])

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    onStateChange({
      ...state,
      [e.target.name]: e.target.value
    })
  };

  const handleAccountFilterChange = async (account_id: string) => {
    onStateChange({
      ...state,
      account_id
    })
  };

  const handleConnectionFilterChange = async (connection_id: string) => {
    onStateChange({
      ...state,
      connection_id
    })
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(state);
  };
  
  const handleBlur = async () => {
    // onSubmit(state);
  };

  const handleReset = async () => {
    onReset();
    setDateFrom(undefined);
    setDateTo(undefined);
    setAccountSearch('');
  };

  useEffect(() => {
    state.date_from = dateFrom ? `${formatISO(dateFrom, {format:'extended', representation:'complete'}).split('+')[0]}.000Z` : '';
  }, [dateFrom])

  useEffect(() => {
    state.date_to = dateTo ? `${formatISO(dateTo, {format:'extended', representation:'complete'}).split('+')[0].slice(0, -2)}59.999Z` : '';
  }, [dateTo])

  useEffect(() => {
    if (!useRangeValue) {
      setDateTo(null);
    }
  }, [useRangeValue]);

  useEffect(() => {
    if (state.account_id && accounts) {
      const account = accounts.find(acc => acc.id === state.account_id);
      fetchAccountConnectionList(account?.connectionIds ?? []);
    }
  }, [accounts, state.account_id]);

  useEffect(() => {
    setConnections(connectionsList)
  }, [connectionsList])

  return (
    <form onSubmit={handleSubmit} onReset={handleReset} className='me-3'>
          <div className='d-flex' style={{flexWrap: 'wrap'}}>
            <div className='d-flex mb-2'>
              <InputGroup className="input-group me-3">
                {godmode ? (
                  <>
                  <FormControl
                    type="text"
                    name="account_id"
                    className='form-control'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={state.account_id}
                    placeholder="Account Id"
                    style={{minWidth: '200px', maxWidth: '250px'}}
                  />
                  <Dropdown>
                    <Dropdown.Toggle split id="dropdown-split-basic" />
                    <Dropdown.Menu style={{minWidth: "400px"}}>
                      <Dropdown.Header className='mb-2'>
                        <FormControl
                          type='text'
                          onChange={(e) => setAccountSearch(e.target.value)}
                          placeholder="Filter accounts"
                        />
                      </Dropdown.Header>
                      <div style={{maxHeight: '400px', overflow: 'auto'}}>
                        {!isLoading && accounts ? (
                          accounts.filter(acc => acc.name.toLowerCase().includes(accountSearch.toLocaleLowerCase())).map(account => {
                            return (
                              <>
                                <Dropdown.Divider className='m-0' />
                                <Dropdown.Item className='py-2' onClick={() => handleAccountFilterChange(account.id)}>{account.name}</Dropdown.Item>
                              </>
                            )
                          })
                        ) : (
                          <Dropdown.Item><Spinner className='mx-2' />Loading accounts</Dropdown.Item>
                        )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  </>
                ) : (<></>)}
                <FormControl
                  type="text"
                  className="form-control"
                  name="connection_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={state.connection_id}
                  placeholder="Connection Id"
                  style={{minWidth: '200px', maxWidth: '250px'}}
                />
                {state.account_id !== "" && !isLoading && accounts ? (
                  <Dropdown>
                      <Dropdown.Toggle split id="dropdown-split-basic" />
                      <Dropdown.Menu style={{minWidth: "400px"}}>
                        <Dropdown.Header className='mb-2'>
                          <FormControl
                            type='text'
                            onChange={(e) => setConnectionSearch(e.target.value)}
                            placeholder="Filter connections"
                          />
                        </Dropdown.Header>
                        <div style={{maxHeight: '410px', overflow: 'auto'}}>
                          {!isLoadingConnections && connections ? (
                            connections.filter(connection => connection.name.toLowerCase().includes(connectionSearch.toLocaleLowerCase())).map(connection => {
                              return (
                                <>
                                  <Dropdown.Divider className='m-0' />
                                  <Dropdown.Item className='py-2' onClick={() => handleConnectionFilterChange(connection.id)}>{connection.name}</Dropdown.Item>
                                </>
                              )})
                          ) : (
                            <Dropdown.Item><Spinner className='mx-2' />Loading connections</Dropdown.Item>
                          )}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                ) : (
                  <Dropdown>
                      <Dropdown.Toggle split id="dropdown-split-basic" variant='secondary' disabled />
                    </Dropdown>
                )}
                <FormControl
                  type="text"
                  className="form-control"
                  name="product_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={state.product_id}
                  placeholder="Product Id"
                  style={{minWidth: '200px', maxWidth: '250px'}}
                />
                <FormControl
                  as='select'
                  type="text"
                  className="form-select"
                  name="connection_channel"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={state.connection_channel}
                  placeholder="Channel"
                  style={{minWidth: '200px', maxWidth: '250px'}}
                >
                  <option value="" className="form-select">All Channels</option>
                  {metaData !== null ? metaData.channels.map((channel) => (
                    <option key={channel.name} className="form-select">{channel.name}</option>
                  )) : (<option key='error_channel'>Error fetching channels</option>)}
                </FormControl>
                {state.connection_channel !== "" ? (
                <FormControl
                  as='select'
                  type="text"
                  className="form-select"
                  name="action"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={state.action}
                  placeholder="Action"
                  style={{minWidth: '200px', maxWidth: '250px'}}
                >
                  <option value=""> All Actions</option>
                  {metaData !== null ? metaData.channels.find(channel => channel.name === state.connection_channel)?.actions.map((action) => (
                    <option key={action} className="form-select">{action}</option>
                  )) : (<option key='error_channel'>Error fetching actions</option>)}
                </FormControl>
                ) : (
                <FormControl
                    type="text"
                    className="form-control"
                    name="action"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={state.action}
                    placeholder="Action"
                    style={{minWidth: '200px', maxWidth: '250px'}}
                  />
                )}
              </InputGroup>
            </div>
            <div className='d-block'>
              <ButtonGroup>
                <Button type='submit' variant='primary'>
                  Filter
                </Button>
                <Button type='reset' className='btn-outline-primary'>
                  Clear
                </Button>
                <Dropdown.Toggle className='btn-outline-primary' onClick={() => setShowMore(!showMore)}>
                  {filterCount > 0 ? (<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{filterCount}</span>) : (<></>)} Additional filters
                </Dropdown.Toggle>
              </ButtonGroup>
            </div>
          </div>
       
          <Accordion activeKey={showMore ? '1' : '0'} className='mb-2'>
            <Accordion.Collapse eventKey='1'>
              <div>
                <Row>
                  <div className='d-flex my-2'>
                  <InputGroup>
                    <FormControl
                      as='select'
                      type="text"
                      className="form-select"
                      name="status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={state.status}
                      placeholder="Status"
                      style={{minWidth: '150px', maxWidth: '250px'}}
                    >
                      <option value="" className="form-select">All Statuses</option>
                      <option value="success" className="form-select">Only Success</option>
                      <option value="error" className="form-select">Only Errors</option>
                      {filterStatus.map((status) => (
                        <option key={status} className="form-select">{status}</option>
                      ))}
                    </FormControl>
                    <FormControl
                      as='select'
                      type="text"
                      className="form-select"
                      name="connection_backend"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={state.connection_backend}
                      placeholder="Backend"
                      style={{minWidth: '150px', maxWidth: '250px'}}
                    >
                      <option value="" className="form-select">All Backends</option>
                      {metaData !== null ? metaData.backends.map((backend) => (
                        <option key={backend} className="form-select">{backend}</option>
                      )) : (<option key='error_backend'>Error fetching backends</option>)}
                    </FormControl>
                    <FormControl
                      as='select'
                      type="text"
                      className="form-select"
                      name="env"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={state.env}
                      placeholder="Env"
                      style={{minWidth: '200px', maxWidth: '250px'}}
                    >
                      <option value="" className="form-select">All Envs</option>
                      {metaData !== null ? metaData.envs.map((env) => (
                        <option key={env} className="form-select">{env}</option>
                      )) : (<option key='error_env'>Error fetching envs</option>)}
                    </FormControl>
                    <FormControl
                      as='select'
                      type="text"
                      className="form-select"
                      name="subrequests"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={state.subrequests}
                      placeholder="Subrequests"
                      style={{minWidth: '200px', maxWidth: '250px'}}
                    >
                      <option value="" className="form-select">With and without subrequests</option>
                      <option value="with" key="with" className="form-select">Only with subrequests</option>
                      <option value="without" key="without" className="form-select">Only without subrequests</option>
                    </FormControl>
                    <FormControl
                      as='select'
                      type="text"
                      className="form-select"
                      name="service"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={state.service}
                      placeholder="Service"
                      style={{minWidth: '200px', maxWidth: '250px'}}
                    >
                      <option value="" className="form-select">All services</option>
                      <option value="octocloud" key="octocloud" className="form-select">octocloud</option>
                      <option value="validation" key="validation" className="form-select">validation</option>
                      <option value="google" key="google" className="form-select">google</option>
                    </FormControl>
                  </InputGroup>
                  </div>
                </Row>
                <Row>
                  <div className='d-flex'>
                  <ButtonGroup className='me-2'>
                      <ToggleButton
                        value={'1'}
                        onClick={() => setUseRangeValue(false)}
                        className={!useRangeValue ? ('primary') : ('btn-outline-primary')}
                      >
                        Day
                      </ToggleButton>
                      <ToggleButton
                        value={'2'}
                        onClick={() => setUseRangeValue(true)}
                        className={useRangeValue ? ('primary') : ('btn-outline-primary')}
                      >
                        Range
                      </ToggleButton>
                    </ButtonGroup>
                    <InputGroup>
                      {useRangeValue ? (
                        <div className='d-flex' style={{minWidth: '200px', maxWidth: '400px'}}>
                          <ReactDatePicker
                            placeholderText='Start date'
                            onChange={(date) => setDateFrom(date)}
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat="dd-MM-yyyy HH:mm"
                            selected={dateFrom}
                            showTimeInput
                            shouldCloseOnSelect={false}
                            showPopperArrow={false}
                          />
                          <ReactDatePicker
                            placeholderText='End date'
                            onChange={(date) => setDateTo(date)}
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat="dd-MM-yyyy HH:mm"
                            selected={dateTo}
                            showTimeInput
                            shouldCloseOnSelect={false}
                            showPopperArrow={false}
                          />
                      </div>
                      ) : (
                        <div className='d-flex' style={{minWidth: '200px', maxWidth: '200px'}}>
                          <ReactDatePicker
                            placeholderText='Date'
                            onChange={(date) => date && setDateFrom(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
                            className="form-control"
                            value={dateFrom ? format(dateFrom, 'dd-MM-yyyy') : ''}
                            showPopperArrow={false}
                          />
                        </div>
                      )}
                    </InputGroup>
                  </div>
                </Row>
              </div>
            </Accordion.Collapse>
          </Accordion>
      {api.error && <Badge bg="danger">{api.error.message}</Badge>}
    </form>
  );
};

export default RequestFilter;