import React, { ReactElement } from 'react';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const Label = ({ className, children }: Props): ReactElement => {
  return (
    <div className={`h6 mb-0 font-weight-bold text-uppercase text-muted ${className}`}>
      {children}
    </div>
  );
};

export default Label;
