import './styles/index.scss';
import './static/robots.txt';
import "react-datepicker/dist/react-datepicker.css";
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);
}
