import { useDispatch } from '../state/dispatch';
import { Account } from '../../types/Account';

export enum AccountListActionType {
  EmptyAccountList = 'EMPTY_ACCOUNT_LIST',
  SetAccountList = 'SET_ACCOUNT_LIST',
  AddAccount = 'ADD_ACCOUNT',
}

interface SetAccountList {
  type: AccountListActionType.SetAccountList;
  payload: Account[];
}

type UseSetList = (list: Account[]) => void;

export const useSetAccountList = (): UseSetList => {
  const dispatch = useDispatch();

  return (list: Account[]) => {
    return dispatch({
      type: AccountListActionType.SetAccountList,
      payload: list,
    });
  };
};

interface AddNewAccount {
  type: AccountListActionType.AddAccount;
  payload: Account;
}

type UseAddAccountToList = (account: Account) => void;

export const useAccountAccountToList = (): UseAddAccountToList => {
  const dispatch = useDispatch();

  return (account: Account) => {
    return dispatch({
      type: AccountListActionType.AddAccount,
      payload: account,
    });
  };
};

export type AccountListAction = SetAccountList | AddNewAccount;
