import * as R from 'ramda';
import React, { ReactElement } from 'react';

import { HttpResponse } from '../../../types/Http';

interface Props {
  response: HttpResponse;
}

const HighlightResponseFirstLine = ({ response }: Props): ReactElement => {
  const status = response.headers['status'] ?? response.status;
  const statusParts = String(status).split(' ');
  const version = response.headers['version'] ?? '1.1';

  return (
    <>
      <span className="hljs-keyword">HTTP/{version}</span>{' '}
      <span className="hljs-number">{statusParts[0]}</span>
      {!R.isNil(statusParts[1]) && <span> {statusParts[1]}</span>}
      {'\n'}
    </>
  );
};

export default HighlightResponseFirstLine;
