import * as R from 'ramda';
import React, { ReactElement } from 'react';

import HighlightHeaders from './components/HighlightHeaders';
import HighlightJson from './components/HighlightJson';
import { HttpRequest, HttpResponse, Json } from '../../types/Http';

interface Props {
  className?: string;
  json?: Json | null;
  request?: HttpRequest | null;
  response?: HttpResponse | null;
}

const Highlighter = ({
  className = '',
  json = null,
  request = null,
  response = null,
}: Props): ReactElement => {
  return (
    <pre
      className={`px-3 py-2 ${className}`}
      style={{
        background: 'transparent',
        border: 'none',
        margin: 0,
        whiteSpace: 'pre',
        maxHeight: 500,
        minHeight: 500,
      }}
    >
      <code>
        {!R.isNil(request) && <HighlightHeaders request={request} />}
        {!R.isNil(response) && <HighlightHeaders response={response} />}
        {!R.isNil(json) && <HighlightJson value={json} />}
      </code>
    </pre>
  );
};

export default Highlighter;
