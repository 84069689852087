import { ReactElement } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ListPage from './scenes/List';
import AccountList from './scenes/AccountList';
import Footer from './components/Footer';
import Header from './components/Header';
import AccountDetails from './scenes/AccountDetails';
import { StateProvider } from './services/state/context';
import { useInit } from './helper/customHooks';
import ConnectionDetails from './scenes/ConnectionDetails';
import ConnectionSearch from './scenes/ConnectionSearch';

const App = (): ReactElement => {
  useInit();
  return (
    <StateProvider>
      <Router>
        <div className="page">
          <Header title="Dashboard" />
          <Switch>
            <Route exact path="/">
              <ListPage />
            </Route>
            <Route path="/accounts/:id">
              <AccountDetails />
            </Route>
            <Route path="/accounts">
              <AccountList />
            </Route>
            <Route path="/connection/:id">
              <ConnectionDetails />
            </Route>
            <Route path="/connection">
              <ConnectionSearch />
            </Route>
          </Switch>
          <Footer />
        </div>
      </Router>
    </StateProvider>
  );
};

export default App;
