import { useLocation } from 'react-router-dom';
import { Account } from '../../types/Account';
import AccountBooking from './AccountBooking';
import { Nav, Row, Spinner, Tab, Table } from 'react-bootstrap';
import AccountConnectionList from '../../components/Account/AccountConnectionList';
import { useEffect, useRef, useState } from 'react';
import RequestList from '../../components/RequestList';
import { useAccountFetchList } from '../../services/accountList/api';
import { useAccountConnectionList, useAccountList } from '../../services/selectors';
import { useAccountConnectionFetchList } from '../../services/accountConnectionList/api';
import { Connection } from '../../types/Connection';
import * as R from 'ramda';

const ViewUserDetails = () => {
  const { pathname } = useLocation();
  const accountId = pathname.split('/')[2];
  const { data: accountList } = useAccountList();
  const { isLoading, fetchAccountList } = useAccountFetchList();
  const { isLoading: isLoadingConnections, fetchAccountConnectionList } = useAccountConnectionFetchList();
  const { data: connectionsList } = useAccountConnectionList();

  const [account, setAccount] = useState<Account>({
    name: '',
    id: accountId,
    connectionIds: []
  });
  const [connections, setConnections] = useState<Connection[]>([]);
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (R.isEmpty(accountList)) {
      fetchAccountList()
    };
  }, []);

  useEffect(() => {
    const accountRetrieved = accountList.find(account => account.id === accountId)
    if (accountRetrieved) {
      setAccount(accountRetrieved);
    } else {
      setAccount({
        name: 'Account Not Found',
        id: accountId,
        connectionIds: []
      })
    };
  }, [accountList])

  useEffect(() => {
    fetchAccountConnectionList(account.connectionIds)
  }, [account]);

  useEffect(() => {
    setConnections(connectionsList)
  }, [connectionsList])
  
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          <h1>Account Details</h1>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="card-title">{account.name}</h2>
                      <p className="card-text">{account.id}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-4'>
              <Tab.Container defaultActiveKey="account_connections">
                <Nav className="nav-tabs">
                  <Nav.Link eventKey="account_connections" key="account_connections">
                    Connections
                  </Nav.Link>
                  {!isLoadingConnections && !R.isEmpty(connections) ? (
                    <>
                    <Nav.Link eventKey="account_bookings" key="account_bookings">
                      Bookings
                    </Nav.Link>
                    <Nav.Link eventKey="account_requests" key="account_requests">
                      Requests
                    </Nav.Link>
                  </>
                  ) : (
                    <></>
                  )}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="account_connections" key="account_connections">
                    {isLoadingConnections ? (
                      <div className="d-flex justify-content-center mt-4">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      connections.length === 0 ? (
                        <Row key="head">
                          <div className="d-flex justify-content-center">
                          <p>No connections found</p>
                          </div>
                        </Row>
                      ) : (
                        <div className="row mt-3">
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Connection Name</th>
                                <th>ID</th>
                              </tr>
                            </thead>
                            <tbody>
                              {connections.map(connection => (
                                    <AccountConnectionList accountId={account.id} connection={connection} />
                                  ))}
                            </tbody>
                          </Table>
                        </div>
                      )
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="account_bookings" key="account_bookings">
                    <AccountBooking key={account.id} accountID={account.id} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="account_requests" key="account_requests">
                    <RequestList showAll={false} accountId={account.id} />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
          )} 
        </div>
      </div>
    </div>
  );
};

export default ViewUserDetails;
