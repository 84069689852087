import { ListAction, ListActionType } from './actions';
import { RequestList } from '../../types/Request';

export interface ListState {
  data: Array<RequestList>;
  listComplete: boolean;
}

export const listInitialState: ListState = {
  data: [],
  listComplete: false
};

function listReducer(state: ListState = listInitialState, action: ListAction): ListState {
  switch (action.type) {
    case ListActionType.AppendList:
      return {
        ...state,
        data: [...state.data, ...action.payload],
      }
    case ListActionType.EmptyList:
      return {
        ...state,
        data: [],
      };
    case ListActionType.SetList:
      return {
        ...state,
        data: action.payload
      };
    case ListActionType.SetListComplete:
      return {
        ...state,
        listComplete: action.payload
      }
    default:
      return state;
  }
}

export default listReducer;
