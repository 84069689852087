import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  label: string;
  group: string;
}

const RadioButton = ({ group, label }: Props): ReactElement => {
  const { register } = useFormContext();

  return (
    <div>
      <label className="form-check form-check-inline">
        <input className="form-check-input" type="radio" {...register(group)} value={label} />
        <span className="form-check-label">{label}</span>
      </label>
    </div>
  );
};

export default RadioButton;
