import { useCallback } from 'react';

import { useDispatch } from '../state/dispatch';
import { RequestDetail } from '../../types/Request';

export enum ModalActionType {
  SetDetail = 'SET_DETAIL',
  SetShow = 'SET_SHOW',
}

type UseSetDetail = (detail: RequestDetail | null) => void;

interface SetDetail {
  type: ModalActionType.SetDetail;
  payload: RequestDetail | null;
}

export const useSetDetail = (): UseSetDetail => {
  const dispatch = useDispatch();

  return useCallback(
    (detail: RequestDetail | null) =>
      dispatch({
        type: ModalActionType.SetDetail,
        payload: detail,
      }),
    [dispatch],
  );
};

type UseSetShow = (show: boolean) => void;

interface SetShow {
  type: ModalActionType.SetShow;
  payload: boolean;
}

export const useSetShow = (): UseSetShow => {
  const dispatch = useDispatch();

  return useCallback(
    (show: boolean) =>
      dispatch({
        type: ModalActionType.SetShow,
        payload: show,
      }),
    [dispatch],
  );
};

export type ModalAction = SetDetail | SetShow;
