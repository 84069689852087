import * as R from 'ramda';
import React, { Fragment, ReactElement } from 'react';

import HighlightHeadersLine from './HighlightHeadersLine';
import HighlightRequestFirstLine from './HighlightHeadersRequestFirstLine';
import HighlightResponseFirstLine from './HighlightHeadersResponseFirstLine';
import { HttpRequest, HttpResponse } from '../../../types/Http';

interface Props {
  request?: HttpRequest | null;
  response?: HttpResponse | null;
}

const HighlightHeaders = ({ request = null, response = null }: Props): ReactElement => {
  const headers = request?.headers ?? response?.headers ?? {};

  return (
    <>
      <span className="highlighter-line-num">1</span>
      {!R.isNil(request) && <HighlightRequestFirstLine request={request} />}
      {!R.isNil(response) && <HighlightResponseFirstLine response={response} />}
      {Object.entries(headers).map(([key, value], index) => (
        <Fragment key={key}>
          <span className="highlighter-line-num">{index + 2}</span>
          <HighlightHeadersLine attr={key} value={value} />
        </Fragment>
      ))}
    </>
  );
};

export default HighlightHeaders;
