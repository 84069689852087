import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap';
import { Account } from '../../types/Account';
import { useAccountFetchList } from '../../services/accountList/api';
import { useAccountList } from '../../services/selectors';
import Table from 'react-bootstrap/Table';
import TableData from '../../components/Account/TableData';
import * as R from 'ramda';
import AccountListPlaceholder from '../../components/Account/AccountListPlaceholder';

const AccountList = (): ReactElement => {
  const [filterText, setFilterText] = useState('');
  const { data: accountList } = useAccountList();
  const { isLoading, fetchAccountList } = useAccountFetchList();
  const [accounts, setAccounts] = useState<Account[]>();
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (R.isEmpty(accountList)) {
      fetchAccountList();
    }
  }, [])

  useEffect(() => {
    setAccounts(accountList)
  }, [accountList])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterText(e.target.value);
  };

  const handleRefreshAccount =  () => {
    fetchAccountList();
  };
  return (
    <div>
      <div className="content">
        <Container fluid>
          <div className="accnt-list-wrapper mb-3 mt-3">
            <Row>
              <Col md={6} xs={8}>
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      placeholder="Filter Accounts by name"
                      onChange={handleChange}
                      value={filterText}
                    />
                  </div>
                </form>
              </Col>

              <Col md={6} xs={4} style={{ display: 'flex', justifyContent: 'end' }}>
                <Button onClick={handleRefreshAccount}>Refresh</Button>
              </Col>
            </Row>
          </div>
            <div className="row mt-3 d-none d-sm-none d-md-block">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{width: "5%"}}>#</th>
                    <th>ID</th>
                    <th>Account Name</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <AccountListPlaceholder />
                  ) : (
                    accounts && (
                      accounts.filter(
                          (account) =>
                            account.name.toLowerCase().includes(filterText.toLowerCase()) ||
                            account.id.toLowerCase().includes(filterText.toLowerCase()),
                        )
                        .map((account: Account, index) => (
                          <TableData key={account.id} account={account} index={index + 1} vertical={false} />
                        ))))}
                </tbody>
              </Table>
            </div>
            <div className="d-block d-sm-block d-md-none">
              <Table striped bordered hover>
                <tbody>
                  {isLoading ? (
                    <AccountListPlaceholder />
                  ) : (
                    accounts && (
                      accounts.filter(
                          (account) =>
                            account.name.toLowerCase().includes(filterText.toLowerCase()) ||
                            account.id.toLowerCase().includes(filterText.toLowerCase()),
                        )
                        .map((account: Account, index) => (
                          <TableData key={account.id} account={account} index={index + 1} vertical={true} />
                        ))))}
                </tbody>
              </Table>
            </div>
        </Container>
      </div>
    </div>
  );
};

export default AccountList;
