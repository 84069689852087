import { useCallback } from 'react';

import { useDispatch } from '../state/dispatch';

export enum ApiActionType {
  SetError = 'SET_ERROR',
  SetGodMode = 'SET_GOD_MODE',
  SetToken = 'SET_TOKEN',
}

interface SetError {
  type: ApiActionType.SetError;
  payload: Error | null;
}

type UseSetError = (payload: Error | null) => void;

export const useSetError = (): UseSetError => {
  const dispatch = useDispatch();

  return useCallback(
    (payload: Error | null) =>
      dispatch({
        type: ApiActionType.SetError,
        payload,
      }),
    [dispatch],
  );
};

interface SetGodMode {
  type: ApiActionType.SetGodMode;
  payload: boolean;
}

type UseGodMode = (payload: boolean) => void;

export const useSetGodMode = (): UseGodMode => {
  const dispatch = useDispatch();

  return useCallback(
    (payload: boolean) =>
      dispatch({
        type: ApiActionType.SetGodMode,
        payload,
      }),
    [dispatch],
  );
};

interface SetToken {
  type: ApiActionType.SetToken;
  payload: string;
}

type UseSetToken = (payload: string) => void;

export const useSetToken = (): UseSetToken => {
  const dispatch = useDispatch();

  return useCallback(
    (payload: string) =>
      dispatch({
        type: ApiActionType.SetToken,
        payload,
      }),
    [dispatch],
  );
};

export type ApiAction = SetError | SetGodMode | SetToken;
